@import '../../../../styles/mediaqueries.scss';
@import '../../../../styles/variables.scss';

.PlayCheckYourReflex {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    h1 span {
      white-space: pre;
    }

    p {
        font-size: 1.1em;

        a,
        a:active,
        a:visited {
            color: $color-purple;
            text-decoration: underline;
        }
    }
}
