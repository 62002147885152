.Graphic {
    position: absolute;
    width: 100%;
    z-index: -1;
}

.Graphic.top    { top: 0; }
.Graphic.bottom { bottom: 0; }
.Graphic.left   { left: 0; }
.Graphic.right  { right: 0; }
.Graphic.middle { top: 50%; transform: translateY(-50%); }
