@import './typography.scss';

// COLORS.
$color-white: #ffffff;
$color-black: #000000;
$color-turquoise: #313e49;
$color-purple: #9e28b5;
$color-blue: #000f9f;
$color-pink: #f4438a;

// FONTS.
$font-black-italic: 'Poppins-BlackItalic', sans-serif;
$font-bold: 'Poppins-Bold', sans-serif;
$font-bold-italic: 'Poppins-BoldItalic', sans-serif;
$font-regular: 'Poppins-Regular', sans-serif;
$font-semi-bold: 'Poppins-SemiBold', sans-serif;
$font-semi-bold-italic: 'Poppins-SemiBoldItalic', sans-serif;

$font-size-sm: 1em;
$font-size-md: 1.33em;
$font-size-lg: 2.22em;
$font-size-xl: 2.66em;

// SPACING.
$space-xs: 4px;
$space-s: 8px;
$space-m: 16px;
$space-l: 32px;
$space-xl: 64px;
$space-xxl: 128px;

$header-height: 120px;

$margin-sides: 15%;
