@import '../../styles/mediaqueries.scss';
@import '../../styles/variables.scss';

.Footer {
    position: relative;
    padding: $space-m 0 $space-l;
    width: 100%;
    background: #323e48;
    font-size: 0.7em;

    .links {
      position: absolute;
      left: 50%;
      bottom: 100%;
      transform: translate(-50%, -100%);

      a,
      a:hover,
      a:visited {
        margin: 0 1em;
        text-decoration: none;
      }
    }

    p {
      color: $color-white;
    }

    img {
      margin: $space-s auto;
      max-width: 180px;
    }
}