@import '../../styles/mediaqueries.scss';
@import '../../styles/variables.scss';

.PrivacyPolicySection {

    main {
        text-align: left;
    }

    h2 {
        margin-bottom: $space-l;
        text-align: center;
    }

    h3 {
        margin-top: $space-l;
    }

    p {
        margin-bottom: 1em;

        b {
            font-family: $font-bold;
        }

        a {
            color: $color-purple;
        }
    }

    ul li {
        display: list-item;
        list-style-type: disc;
    }

}
