@font-face {
    font-family: 'Poppins-BlackItalic';
    src: url('/fonts/Poppins-BlackItalic.eot');
    src: url('/fonts/Poppins-BlackItalic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Poppins-BlackItalic.woff2') format('woff2'),
        url('/fonts/Poppins-BlackItalic.woff') format('woff');
}

@font-face {
    font-family: 'Poppins-Black';
    src: url('/fonts/Poppins-Black.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins-Bold';
    src: url('/fonts/Poppins-Bold.eot');
    src: url('/fonts/Poppins-Bold.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Poppins-Bold.woff2') format('woff2'),
        url('/fonts/Poppins-Bold.woff') format('woff');
}

@font-face {
    font-family: 'Poppins-BoldItalic';
    src: url('/fonts/Poppins-BoldItalic.eot');
    src: url('/fonts/Poppins-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Poppins-BoldItalic.woff2') format('woff2'),
        url('/fonts/Poppins-BoldItalic.woff') format('woff');
}

@font-face {
    font-family: 'Poppins-Regular';
    src: url('/fonts/Poppins-Regular.eot');
    src: url('/fonts/Poppins-Regular.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Poppins-Regular.woff2') format('woff2'),
        url('/fonts/Poppins-Regular.woff') format('woff');
}

@font-face {
    font-family: 'Poppins-Italic';
    src: url('/fonts/Poppins-Italic.ttf') format('truetype');
}

@font-face {
    font-family: 'Poppins-SemiBold';
    src: url('/fonts/Poppins-SemiBold.eot');
    src: url('/fonts/Poppins-SemiBold.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Poppins-SemiBold.woff2') format('woff2'),
        url('/fonts/Poppins-SemiBold.woff') format('woff');
}


@font-face {
    font-family: 'Poppins-SemiBoldItalic';
    src: url('/fonts/Poppins-SemiBoldItalic.eot');
    src: url('/fonts/Poppins-SemiBoldItalic.eot?#iefix') format('embedded-opentype'),
        url('/fonts/Poppins-SemiBoldItalic.woff2') format('woff2'),
        url('/fonts/Poppins-SemiBoldItalic.woff') format('woff');
}

