@import '../../styles/variables.scss';
@import '../../styles/mediaqueries.scss';

.Header {
    position: relative;
    z-index: 2;
    overflow: hidden;

    border-bottom: 5px solid $color-white;
    background: #001689 url('./img/bg.png') repeat-x center center;
    background-size: contain;
    box-shadow: 0 2px 6px 4px rgba(#00b289, 0.75);

    display: flex;
    justify-content: center;
    align-items: flex-end;

    width: 100%;
    height: $header-height;
    padding: 15px 0 5px;

    @include mobile {
        height: $header-height * 2 / 3;
    }

    &:after {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);

        width: 200%;
        height: 1px;
        box-shadow: 0 -1px 8px 1px rgba($color-white, 0.65);

        content: ' ';
    }
}

.Header a:not(.button) {
    height: 100%;
}

.Header img {
    width: auto;
    height: 100%;
}

.Header .language-toggle {
    position: absolute;
    right: 0;
    top: 0;
    padding: 15px;

    display: inline-flex;
    align-items: center;

    @include mobile {
        width: 100%;
        justify-content: space-between;
        pointer-events: none;

        * { pointer-events: all }
    }

    a {
        color: $color-white;
    }

    .button {
        margin: 0 15px;
        background: $color-purple;

        @include mobile {
            margin: 0;
            padding: 5px 8px;
            font-size: 0.85em;
            border-width: 1px;
        }
    }
}