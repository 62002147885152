@import '../../styles/variables.scss';

.BGLines {
    .Graphic {
        width: 25%;
        max-width: 300px;
    }

    .bg-lines-top { top: $space-s }
    .bg-lines-bottom { bottom: $space-xl }
}