@import '../../../../styles/mediaqueries.scss';
@import '../../../../styles/variables.scss';

.Suggestions {
    padding: 0;

    h3,
    p {
        margin-bottom: 1.5em;
    }

    p {
        color: $color-purple;
        font-size: $font-size-lg;
    }

    ul li {
        position: relative;
        padding-bottom: $space-m;

        color: $color-blue;
        font-size: $font-size-md;
        line-height: 1.2;
    }

    ul li:not(:first-of-type) {
        margin-top: $space-m;
    }

    ul li:not(:last-of-type):after {
        position: absolute;
        left: 50%;
        top: 100%;
        transform: translate(-50%, -50%);

        content: ' ';

        width: 30px;
        height: 2px;
        background: #00b289;
    }
}
