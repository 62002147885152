@import './reset.scss';
@import './mediaqueries.scss';
@import './variables.scss';

html, body, #root {
    width: 100%;
    height: 100%;
    font-size: 16px;

    @include desktop {
        font-size: 14px;
    }

    @include mobile-less {
        font-size: 12px;
    }
}

body {
    margin: 0;

    color: $color-turquoise;
    font-family: $font-regular;
    line-height: 1.4;
    text-align: center;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    overflow-x: hidden;
}

a,
a:visited,
a:active,
a:hover {
    color: currentColor;
    text-decoration: none;
}

h1 {
    font-family: 'Poppins-Black', $font-bold;
    font-size: $font-size-xl;
    line-height: 1;
    text-transform: uppercase;
}


h2 {
    margin-bottom: 0.3em;
    color: $color-purple;
    font-family: $font-bold-italic;
    font-size: $font-size-lg;
    text-transform: uppercase;
    line-height: 1;
}

h3 {
    margin-bottom: 0.8em;
    font-family: $font-regular;
    font-size: $font-size-md;
    line-height: 1.15;
}

p {
    font-size: $font-size-sm;
    line-height: 1.3;
}

.visually-hidden {
    position: absolute;
    clip: rect(1px 1px 1px 1px); /* for Internet Explorer */
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0;
    border: 0;
    height: 1px;
    width: 1px;
    overflow: hidden;
}

.container {
    width: 80%;
    max-width: 900px;
    margin: auto;
}

main {
    padding: 7% 0;

    > section {
        padding: $space-l 0;

        h3,
        ul,
        p {
            margin-left: auto;
            margin-right: auto;
            max-width: 620px;
        }
    }
}

button.button,
a.button,
a.button:visited,
a.button:active,
a.button:hover {
    margin: $space-l;
    display: inline-block;
    padding: 10px 25px;

    outline: none;
    border-radius: 0;
    border: 2px solid $color-white;
    box-shadow: 0 0 8px 2px rgba($color-pink, 0.85);
    background: $color-pink;
    color: $color-white;

    font-family: $font-semi-bold;
    font-size: 1.33rem;
    text-transform: uppercase;

    transition: all 0.3s ease-in-out;

    &:not(:disabled) {
        cursor: pointer;
    }

    &:disabled {
        background: #cdcdcd;
        box-shadow: none;
    }
}
