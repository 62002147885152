@import '../../../../styles/variables.scss';

.Contact {
    blockquote {
        position: relative;
        display: block;
        padding: 1em;
        font-size: $font-size-md;

        &:before,
        &:after {
            position: absolute;
            top: 50%;
            content: ' ';

            width: 3em;
            height: 6em;

            background: url('./img/bracket.png') no-repeat center center;
            background-size: contain;
        }

        &:before {
            transform: translateY(-50%);
            right: 100%;
        }

        &:after {
            left: 100%;
            transform: translateY(-50%) rotate(180deg);
        }
    }

    h2 {
        font-family: $font-regular;
        color: $color-purple;
        text-transform: none;

        b { font-family: $font-semi-bold }
    }

    p {
        color: $color-blue;

        span { display: block }

        a,
        a:hover,
        a:visited { color: $color-blue }
    }
}