@import '../../styles/mediaqueries.scss';
@import '../../styles/variables.scss';

.HomeSection {
    position: relative;
    width: 100%;

    .BGLines,
    .BGEmojis {
        z-index: -1;
    }

    .BGEmojis {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        width: 100%;
        height: 55%;
    }

    main {
        min-height: 150vw;

        @include tablet-less {
            min-height: 170vw;
        }

        @include desktop-more {
            min-height: 100vw;
        }

        @include screen-max-more {
            min-height: 80vw;
        }
    }
}
