@import '../../../../styles/variables.scss';
@import '../../../../styles/mediaqueries.scss';

.LetsTalk {
    position: relative;
    width: 100%;
    overflow: hidden;

    ul li {
        position: relative;
    }

    ul li:first-of-type,
    ul li:last-of-type {
        &:after {
            position: absolute;
            content: ' ';
        }
    }

    .letstalk-facts {
        margin: $space-m auto $space-l;
        display: flex;
        justify-content: stretch;

        @include mobile {
          display: block;

          li:after {
            display: none;
          }
        }

        li {
            flex-grow: 1;
            padding: 16px 25px;
            color: #23217c;
            font-family: 'Poppins-Italic';
            font-size: $font-size-md;
            line-height: 1;
            text-transform: uppercase;
        }

        li:first-of-type,
        li:last-of-type {
            &:after {
                top: 50%;
                transform: translate(-50%, -50%);
                width: 2px;
                height: 100%;
                background: $color-white;
                box-shadow: 0 0 10px 2px rgba($color-purple, 0.75);
            }
        }

        li:first-of-type:after { left: 100% }
        li:last-of-type:after { left: 0% }
    }

    .letstalk-effects {
        li {
            padding: 12px 0;
            font-size: $font-size-md;
            color: $color-purple;
        }

        li:first-of-type,
        li:last-of-type {
            &:after {
                left: 50%;
                transform: translate(-50%, -50%);
                width: 30px;
                height: 2px;
                background: currentColor;
            }
        }

        li:first-of-type:after { top: 100% }
        li:last-of-type:after { top: 0% }
    }
}
